import * as _ from 'lodash';
import {stringify} from 'qs';
import {LocaleEnum, LocaleType} from 'src/types/general';
import {Feature, FeatureCollection, GeoJsonProperties, Geometry} from 'geojson';
import {IconType} from '@components/Icon/Icon';
type Input = {query?: string; rank?: number; size?: number};
type Extra = {
    addressdetails?: number;
    osm_ids?: string;
    lat?: number;
    lon?: number;
};

export type NominatimOsmId = `N${number}` | `R${number}` | `W${number}`;

export type AutocompleteProperties = {
    osm_type: 'R' | 'W' | 'N';
    osm_id: string;
    place_rank: number;
    local_type?: string;
    label: string;
    class: string;
    type: string;
    street: string;
    name?: string;
    city: string;
    house_number?: string;
    country_code: string;
    display_name?: string;
    icon?: IconType;
    extratags?: Record<string, any>;
    address?: Record<string, any>;
} & GeoJsonProperties;

const placeRankToTypeMapping: Record<number, string> = {
    6: 'state',
    8: 'state',
    12: 'region',
    14: 'state_district',
    16: 'city',
    20: 'city_district',
};

const autocompleteTypeMapping: Record<LocaleType, Record<string, string>> = {
    [LocaleEnum.cs]: {
        address: 'adresní bod',
        highway: 'ulice',
        country: 'země',
        peak: 'vrchol',
        river: 'řeka',
        stream: 'potok',
        suburb: 'městská část',
        city: 'město',
        water: 'vodní plocha',
        hamlet: 'osada',
        tertiary: 'ulice',
        village: 'vesnice',
        secondary: 'ulice',
        station: 'stanice',
        primary: 'ulice',
        district: 'městská část / obvod',
        neighbourhood: 'čtvrť',
        state_district: 'okres',
        city_district: 'městská část',
        country_region: 'region',
        region: 'kraj',
        state: 'region soudržnosti',
        locality: 'lokalita',
        aerodrome: 'letiště',
        residential: 'ulice',
        mountain_range: 'pohoří',
        protected_area: 'chráněné území',
        subway: 'stanice metra',
        street: 'ulice',
        town: 'město',
        house: 'dům',
        isolated_dwelling: 'samota',
    },
    [LocaleEnum.sk]: {
        address: 'adresný bod',
        highway: 'ulica',
        country: 'krajina',
        peak: 'vrchol',
        river: 'rieka',
        stream: 'potok',
        suburb: 'mestská časť',
        city: 'mesto',
        water: 'vodná plocha',
        hamlet: 'dedinka',
        tertiary: 'ulica',
        village: 'dedina',
        secondary: 'ulica',
        station: 'stanice',
        primary: 'ulice',
        district: 'mestský obvod',
        neighbourhood: 'čtvrť',
        state_district: 'okres',
        city_district: 'mestská časť',
        country_region: 'region',
        region: 'kraj',
        state: 'kraj',
        locality: 'lokalita',
        aerodrome: 'letisko',
        residential: 'ulica',
        mountain_range: 'pohorie',
        protected_area: 'chránené územie',
        subway: 'stanice metra',
        street: 'ulice',
        town: 'dedina',
        house: 'dom',
        isolated_dwelling: 'samota',
    },
    [LocaleEnum.en]: {
        address: 'address',
        highway: 'street',
        country: 'country',
        peak: 'peak',
        river: 'river',
        stream: 'stream',
        suburb: 'neighbourhood',
        city: 'city',
        water: 'water area',
        hamlet: 'hamlet',
        tertiary: 'street',
        village: 'village',
        secondary: 'street',
        station: 'station',
        primary: 'street',
        district: 'city district',
        neighbourhood: 'neighbourhood',
        state_district: 'district',
        city_district: 'neighbourhood',
        country_region: 'macroregion',
        region: 'region',
        state: 'region',
        locality: 'place',
        aerodrome: 'aerodrome',
        residential: 'street',
        mountain_range: 'mountain range',
        protected_area: 'protected area',
        subway: 'subway station',
        street: 'street',
        town: 'town',
        house: 'house',
        isolated_dwelling: 'isolated dwelling',
    },
};

export class AutocompleteHelper {
    constructor(
        protected autocomplete_url: string,
        protected nominatim_url: string,
        protected street_url: string,
        protected polygonOutput = false,
        protected simplifyPolygon = true,
        protected suggestionLimit = 20,
        protected locale: LocaleType = LocaleEnum.cs,
        protected address: boolean | null = null,
        protected preferredCountry = '',
        protected withExtraTags = false,
    ) {}

    getIcon = (feature: Feature<Geometry, AutocompleteProperties>): IconType => {
        const cls = feature.properties.class;
        const type = feature.properties.type;
        const extratags = feature.properties.extratags;

        if (cls === 'natural') {
            return 'Mountain';
        } else if (cls === 'railway' && type === 'station' && extratags?.hasOwnProperty('station') && extratags.station === 'subway') {
            return 'Subway';
        }

        return 'Location';
    };

    getCityLabel = (feature: Feature<Geometry, AutocompleteProperties>) => {
        const address = feature.properties.address ?? {};
        const parts = [
            address.hamlet,
            address.suburb,
            address.village,
            address.town,
            address.city,
            address.municipality,
            address.county,
            address.state,
            address.country,
        ];

        return parts
            .filter((part) => !!part)
            .filter((part, idx, arr) => arr.length === idx + 1 || arr[idx + 1] !== part) // eliminate duplicates
            .join(', ');
    };

    getTypeKeyTranslation = (type: string) => {
        if (!autocompleteTypeMapping[this.locale][type]) {
            return type;
        }

        return autocompleteTypeMapping[this.locale][type];
    };

    getLocalizedType = (feature: Feature<Geometry, AutocompleteProperties>) => {
        const cls = feature.properties.class;
        const type = feature.properties.type;
        const extratags = feature.properties.extratags;

        //console.log(feature.properties);

        if (['place', 'natural'].includes(cls)) {
            return this.getTypeKeyTranslation(type);
        } else if (cls === 'highway') {
            return this.getTypeKeyTranslation('street');
        } else if (cls === 'railway' && type === 'station' && extratags?.hasOwnProperty('station') && extratags.station === 'subway') {
            return this.getTypeKeyTranslation('subway');
        } else if (cls === 'boundary' && type !== 'administrative') {
            return this.getTypeKeyTranslation(type);
        } else if (cls === 'boundary' && type === 'administrative' && placeRankToTypeMapping[feature.properties.place_rank]) {
            return this.getTypeKeyTranslation(placeRankToTypeMapping[feature.properties.place_rank]);
        } else if (cls === 'building') {
            return this.getTypeKeyTranslation('house');
        }

        return '';
    };

    getAcceptLanguageValue = () => {
        return (this.locale ? this.locale + ', ' : '') + '*;q=0.1';
    };

    getNominatimPolygonThresholdByPlaceRank = (rank: number) => {
        if (rank < 8) {
            return 0.002;
        } else if (rank < 12) {
            return 0.001;
        } else if (rank <= 30) {
            return 0.001;
        }

        return 0;
    };

    getNominatimLabel = (feature: any) => {
        if ('house_number' in feature.properties.address) {
            const parts = feature.properties.display_name.split(', ');
            const houseNumber = parts.shift();
            parts[0] += ' ' + houseNumber;
            return parts.join(', ');
        }

        return feature.properties.display_name;
    };

    processFeature = (feature: Feature<Geometry, AutocompleteProperties>) => {
        feature.properties.label = this.getNominatimLabel(feature);
        feature.properties.icon = this.getIcon(feature);

        if (feature.properties.name?.startsWith('okres')) {
            // Trip 'okres' prefix
            feature.properties.name = feature.properties.name.substr(6);
            if (feature.properties.display_name) {
                feature.properties.display_name = feature.properties.display_name.substr(6);
            }
            if (feature.properties.label) {
                feature.properties.label = feature.properties.label.substr(6);
            }
        }

        if (feature.properties.type === 'address') {
            if (feature.properties.display_name) {
                const parts = feature.properties.display_name.split(', ');
                const number = parts.splice(0, 1)[0];
                parts[0] += ` ${number}`;
                feature.properties.display_name = parts.join(', ');
            }
            if (feature.properties.label) {
                const parts = feature.properties.label.split(', ');
                const number = parts.splice(0, 1)[0];
                parts[0] += ` ${number}`;
                feature.properties.label = parts.join(', ');
            }
        }

        return feature;
    };

    nominatimQueryParameters = ({query, rank, size}: Input, extra: Extra, withPolygon?: boolean, withExtraTags?: boolean) => {
        const data: {
            limit: number;
            format: string;
            q?: string;
            polygon_geojson?: number;
            polygon_threshold?: 0 | 0.001 | 0.002;
            extratags?: number;
        } = {
            limit: size ? size : this.suggestionLimit,
            format: 'geojson',
            q: query,
        };

        if (withPolygon) {
            data.polygon_geojson = 1;

            if (this.simplifyPolygon) {
                data.polygon_threshold = rank ? this.getNominatimPolygonThresholdByPlaceRank(rank) : 0;
            }
        }

        if (withExtraTags) {
            data.extratags = 1;
        }

        return _.assign({}, data, extra);
    };

    suggestionDetail = (suggestion: Feature<Geometry, AutocompleteProperties>) => {
        const type = suggestion.properties.osm_type;

        return this.lookup((type + suggestion.properties.osm_id) as NominatimOsmId, suggestion.properties.place_rank);
    };

    getStreetPolygon = (street_id: string | number) => {
        return fetch(this.street_url + '?id=' + street_id).then((response) => {
            return response.json();
        });
    };

    lookup = (osmId: NominatimOsmId | NominatimOsmId[], rank?: number) => {
        const url = this.nominatim_url + '/lookup';
        const data = this.nominatimQueryParameters(
            {
                rank,
                //query: suggestion.properties.display_name,
            },
            {
                addressdetails: 1,
                osm_ids: Array.isArray(osmId) ? osmId.join(',') : osmId,
            },
            this.polygonOutput,
            this.withExtraTags,
        );

        return fetch(url + '?' + stringify(data), {
            method: 'get',
            headers: {
                'Accept-Language': this.getAcceptLanguageValue(),
            },
        })
            .then((response) => response.json())
            .then(async (response: FeatureCollection<Geometry, AutocompleteProperties>) => {
                for (const feature of response.features) {
                    if (['LineString', 'MultiLineString'].includes(feature.geometry.type)) {
                        const polygon = await this.getStreetPolygon(feature.properties.osm_id);
                        if (polygon) {
                            feature.geometry = polygon;
                        }
                    }

                    this.processFeature(feature);
                }
                return response;
            });
    };

    reverse = (center: number[]) => {
        const url = this.nominatim_url + '/reverse';
        const data = this.nominatimQueryParameters(
            {
                size: 1,
            },
            {
                lat: center[1],
                lon: center[0],
            },
            this.polygonOutput,
            this.withExtraTags,
        );
        return fetch(url + '?' + stringify(data), {
            method: 'get',
            headers: {
                'Accept-Language': this.getAcceptLanguageValue(),
            },
        })
            .then((response) => response.json())
            .then((response: FeatureCollection<Geometry, AutocompleteProperties>) => {
                for (const feature of response.features) {
                    this.processFeature(feature);
                }
                return response;
            });
    };

    search = (text: string) => {
        const url = this.nominatim_url + '/search';
        const {suggestionLimit} = this;

        const data = this.nominatimQueryParameters(
            {
                query: text,
                size: suggestionLimit,
            },
            {},
            this.polygonOutput,
            this.withExtraTags,
        );
        return fetch(url + '?' + stringify(data), {
            method: 'get',
            headers: {
                'Accept-Language': this.getAcceptLanguageValue(),
            },
        })
            .then((response) => response.json())
            .then((response: FeatureCollection<Geometry, AutocompleteProperties>) => {
                for (const feature of response.features) {
                    this.processFeature(feature);
                }
                return response;
            });
    };

    autocomplete = (text: string, options?: Omit<RequestInit, 'method' | 'headers'>, from = 0) => {
        if (text === '') {
            return Promise.resolve({
                type: 'FeatureCollection',
                features: [],
            } as FeatureCollection<Geometry, AutocompleteProperties>);
        }

        const url = this.autocomplete_url + '/autocomplete';
        const {suggestionLimit} = this;
        const data = {
            q: text,
            size: suggestionLimit,
            address: 0,
            preferredCountry: '',
            from,
        };
        if (this.address !== null) {
            data.address = this.address ? 1 : 0;
        }
        if (this.preferredCountry !== null) {
            data.preferredCountry = this.preferredCountry;
        }
        return fetch(url + '?' + stringify(data), {
            method: 'get',
            headers: {
                'Accept-Language': this.getAcceptLanguageValue(),
            },
            ...options,
        })
            .then((response) => response.json())
            .then((response: FeatureCollection<Geometry, AutocompleteProperties>) => {
                for (const feature of response.features) {
                    this.processFeature(feature);
                    feature.properties.local_type = this.getLocalizedType(feature);
                }

                return response;
            });
    };

    deduplicate = (features: Feature<Geometry, AutocompleteProperties>[]) => {
        return features.reduce((carry: Feature<Geometry, AutocompleteProperties>[], feature: Feature<Geometry, AutocompleteProperties>) => {
            const pos = carry.findIndex(
                (f: any) => f.properties.label === feature.properties.label && f.properties.class === feature.properties.class,
            );
            const orig = carry[pos];

            if (pos !== -1 && orig && (feature.properties.class === 'highway' || orig.properties.type === feature.properties.type)) {
                if (
                    orig.properties.osm_type === 'R' ||
                    (orig.properties.osm_type === 'W' && feature.properties.osm_type !== 'R') ||
                    (orig.properties.osm_type === 'N' && feature.properties.osm_type === 'N')
                ) {
                    return carry; // Do not include second output as it is not as usefull
                } else {
                    carry[pos] = feature; // Change output
                    return carry;
                }
            } else {
                carry.push(feature); // Add item as it is different
            }

            return carry;
        }, []);
    };
}
